<template>
  <div>
    <ml-container>
      <ml-grid>
        <div class="tw-mt-12 tw-text-center tw-text-black tw-col-start-2 tw-col-span-6">
          <page-title>Jobs</page-title>
          <p class="tw-mb-8 tw-leading-relaxed">
            Thank you for your interest in being part of the Mango & Lola team. Please send a copy of your resume to
            <a href="mailto:jobs@mangolola.com" class="tw-text-red">jobs@mangolola.com</a> and someone will reply
            back if your background is a match for any of our current job opportunities
          </p>
          <div>
            <strong>At Mango & Lola It’s OK to...</strong><br>
            say “I don’t know”<br>
            ask for more clarity<br>
            stay at home when you feel ill<br>
            say you don’t understand<br>
            ask what acronyms stand for<br>
            forget things<br>
            introduce yourself<br>
            depend on the team<br>
            ask for help<br>
            not know everything<br>
            have quiet days<br>
            have loud days, to talk, joke, and laugh<br>
            put your headphones on<br>
            say “No” when you’re too busy<br>
            make mistakes<br>
            sing<br>
            sigh<br>
            not check your email out of hours<br>
            not check your email constantly during hours<br>
            just Slack it<br>
            walk over and ask someone face-to-face<br>
            offer feedback on other people’s work<br>
            challenge things you’re not comfortable with<br>
            say yes when anyone does a coffee run<br>
            prefer tea<br>
            snack<br>
            have a messy desk<br>
            have a tidy desk<br>
            work how you like to work<br>
            ask the management to fix it<br>
            have off-days<br>
            have days off<br>
            and lastly be the best possible version of yourself<br>
          </div>
        </div>
      </ml-grid>
    </ml-container>
  </div>
</template>

<script>
import i18n from '@/i18n'
import MlContainer from "@/components/layout/MlContainer";
import PageTitle from "@/components/layout/titles/PageTitle";
import MlGrid from "@/components/layout/MlGrid";

export default {
  components: {
    MlGrid,
    PageTitle,
    MlContainer,
  },
  layout: 'basic',
  metaInfo() {
    return {
      title: i18n.t('jobs'),
      meta: [
        {
          name: 'description',
          content: i18n.t('description.jobs')
        }
      ]
    }
  },
}
</script>
